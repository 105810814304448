<template>
  <UiTourDriver
    ref="driver"
    :highlight
    :tour="TOURS_IDS.CALL_LOG"
    :steps
    @destroyed="useMittEvent('tour:clearCallLogsMock')"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS } from '~/constants'

const highlight = ref<DriveStep>({
  element: '#call-log',
  popover: {
    title: 'Call log',
    description: `Now you can easily monitor both your incoming and outgoing calls, 
          along with detailed information, all conveniently stored in your Call Log.`,
  },
})

const isCallLogPageMounted = useState('isCallLogPageMounted', () => false)

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

const style = ref<HTMLStyleElement | null>(null)

const isFilterLoaded = ref(false)

onMounted(() => {
  // @ts-ignore
  useMittListen('tableFilters:loaded', (id: string) => {
    if (id === 'callLogsFilters') {
      isFilterLoaded.value = true
    }
  })
})

onUnmounted(() => {
  useMittRemove('tableFilters:loaded')
})

const highlightSelectDropdown = async (id: string) => {
  const select = document.querySelector<HTMLElement>(`#${id}`)

  if (select) {
    select.querySelector('input')?.focus()

    await nextTick()

    const { x, y, height, width } = select.getBoundingClientRect()

    const selectDropdown = document.querySelector<HTMLElement>(`#${id}_select`)

    if (selectDropdown) {
      const { height: selectDropdownHeight } = selectDropdown.getBoundingClientRect()

      const tempBlock = document.createElement('div')

      tempBlock.id = `${id}_select_temp_block`

      if (document.querySelector(`#${id}_select_temp_block`)) {
        // Block already exists
        return
      }

      tempBlock.style.cssText = `height: ${
        height + selectDropdownHeight
      }px; left: ${x}px; position: absolute; top: ${y}px; width: ${width}px; pointer-events: none;`

      document.body.appendChild(tempBlock)
    }
  }
}

const createTemporaryBlock = async () => {
  await until(isFilterLoaded).toBe(true, {
    timeout: 3000,
  })

  await highlightSelectDropdown('status_ids')

  if (style.value) {
    document.head.removeChild(style.value)
    style.value = null
  }
}

const createTemporaryStyle = () => {
  const label = document.querySelector('#incoming')

  if (label) {
    const { x } = label.getBoundingClientRect()

    const popoverContent = document.querySelector<HTMLElement>('#driver-popover-content')

    if (popoverContent) {
      style.value = document.createElement('style')
      style.value.innerHTML = `.left-offset { left: ${x}px !important; }`
      document.head.appendChild(style.value)
    }
  }
}

const steps = computed<DriveStep[]>(() => [
  {
    element: '#call-log',
    popover: {
      title: '1/5 Start from Pipeline',
      description: `New button in Pipeline! Click for your private call history, seen by you alone.`,
      onNextClick: async () => {
        await navigateTo({
          name: 'logs-call',
          query: {
            dateRange: 'last_3_days',
            direction_ids: 1,
            page: 1,
            limit: 10,
          },
        })

        await until(isCallLogPageMounted).toBe(true)

        await nextTick()

        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#callType',
    popover: {
      title: '2/5 Incoming calls',
      description: `The table defaults to displaying incoming calls, marked by an icon in the "Call Type" column.`,
      showButtons: ['next', 'prev'],
      onNextClick: async () => {
        useMittEvent('tour:setCallLogsMock')

        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }

        await nextTick()

        createTemporaryStyle()
      },
    },
    onHighlighted: () => {
      const callLogsFilters = document.querySelector<HTMLElement>('#callLogsFilters')

      const isFilterOpened = callLogsFilters?.classList.contains('shadow-down')

      if (!isFilterOpened) {
        const filterButton = document.querySelector<HTMLElement>('#filters_applied')

        if (filterButton) {
          filterButton.click()
        }
      }
    },
  },
  {
    element: '#callLogsTable',
    popover: {
      title: '3/5 All calls',
      description: `Easily view incoming and outgoing calls separately or together.`,
      align: 'start',
      side: 'bottom',
      popoverClass: 'left-offset',
      onNextClick: async () => {
        await createTemporaryBlock()

        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#status_ids_select_temp_block',
    popover: {
      title: '4/5 Specify by call status',
      description: `Each call gets a status, like missed calls. You can focus on specific statuses in your history.`,
      align: 'start',
      side: 'right',
      onPrevClick: () => {
        createTemporaryStyle()

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: async () => {
        await highlightSelectDropdown('date-range')

        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#date-range_select_temp_block',
    popover: {
      title: '5/5 Specify by time period',
      description: `Don't forget to use a time period! Happy exploring with the call log, here to assist you!`,
      align: 'start',
      side: 'left',
      onPrevClick: () => {
        createTemporaryBlock()

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: () => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.destroy()
        }

        // Remove focus from select
        if (document.activeElement) {
          ;(document.activeElement as HTMLElement)?.blur()
          document.body.click()
        }
      },
    },
  },
])

onUnmounted(() => {
  if (style.value) {
    document.head.removeChild(style.value)
  }
})
</script>

<style scoped></style>
